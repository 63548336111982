
import createPersistedState from 'use-persisted-state';

export function useSerialisedLocalStoreState<T>(key: string, initialValue: T): [T, (newValue: T) => void] {
    const usePersistedState = createPersistedState(key);
    const [state, setState] = usePersistedState(JSON.stringify(initialValue));

    const parsedState = JSON.parse(state);
    const serialiseAndSetState = (newValue: T): void => {
        setState(JSON.stringify(newValue));
    }

    return [parsedState, serialiseAndSetState];
}
