
import { useState, Dispatch, SetStateAction } from 'react';
import { useSerialisedLocalStoreState } from '../../state/localStore';

interface Config {
    selectedMethod: string,
    methods: {
        [method: string]: {
            code: string,
            response: string,
        }
    }
}

const valueForEachMethod = (methods: string[], value: any) => methods
    .map(method => ({[method]: value}))
    .reduce((acc, cur) => ({...acc, ...cur}), {});

export const defaultConfig = (methods: string[]): Config => ({
    selectedMethod: methods[0],
    methods: valueForEachMethod(methods, {code: '', response: ''})
});

type DSSA<T> = Dispatch<SetStateAction<T>>;

export default class EditorState {

    private readonly config: Config;
    private readonly storeConfig: (newConfig: Config) => void;

    private readonly error: string;
    readonly setError: DSSA<string>;

    private readonly running: boolean;
    readonly setRunning: DSSA<boolean>;

    constructor(methods: string[]) {
        [this.config, this.storeConfig] = useSerialisedLocalStoreState('appConfig2', defaultConfig(methods));
        [this.error, this.setError] = useState('');
        [this.running, this.setRunning ] = useState(false);
    }

    getSelectedMethod() {
        return this.config.selectedMethod;
    }

    getCode() {
        return this.config.methods[this.getSelectedMethod()].code;
    }

    getResponse() {
        return this.config.methods[this.getSelectedMethod()].response;
    }

    getError() {
        return this.error;
    }

    getRunning() {
        return this.running;
    }

    public setSelectedMethod = (newSelectedMethod: string) => {
        this.config.selectedMethod = newSelectedMethod;
        this.storeConfig(this.config);
    }

    public setCode = (newCode: string) => {
        this.config.methods[this.getSelectedMethod()].code = newCode;
        this.storeConfig(this.config);
    }

    public setResponse = (newResponse: string) => {
        this.config.methods[this.getSelectedMethod()].response = newResponse;
        this.storeConfig(this.config);
    }
}
