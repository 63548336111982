
import { CdkSpaClient, RequestFactoryType, ApiResponse } from '@cfedk/cdkspa-client';

export class ClientFactory {

    // Should come from swagger?
    private readonly DEFAULT_BASE_URL = '/api';

    makeDefaultClient(): CdkSpaClient {
        return this.makeClient(this.DEFAULT_BASE_URL);
    }

    makeClient(baseUrl: string): CdkSpaClient {

        const requestFactory: RequestFactoryType = (path: string, query: any, body: any, formData: any, headers: any, method: string, configuration: any): Promise<ApiResponse<any>> => {
            const url = baseUrl + path;
            return fetch(url, {
                method,
                body,
                headers,
            })
            .then(response => {
                if(!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            });
        }

        return new CdkSpaClient({}, requestFactory);
    }
}
