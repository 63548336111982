import * as React from 'react';
import { EuiCallOut } from '@elastic/eui';

export default (props: { error?: string }) => {
    return (
        <EuiCallOut
            title={props.error}
            color={'danger'}
            iconType={'alert'}
            hidden={!props.error}
            style={{ marginTop: 16}}
        />
    );
}