import * as React from 'react';

import { ClientFactory } from '../../client';

import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel
} from '@elastic/eui';

import MethodSelector from './MethodSelector';
import Input from './Input';
import Output from './Output';
import Error from './Error';

import EditorState from './state';

import { clickHandler } from './actions';

export const Editor = () => {

    const client = new ClientFactory().makeDefaultClient();

    const methods = [
        'getUsersById',
        'postUsers',
        'getUsersSubResourceBySubResourceId'
    ];

    const stateHandler = new EditorState(methods);

    const [valid, setValid] = React.useState(false);
    const submissionDisabled = !valid || stateHandler.getRunning();

    const handleClick = clickHandler(stateHandler, client);

    return (
        <div>
            <EuiFlexGroup style={{height: '700px'}}>
                <EuiFlexItem>
                    <EuiFlexGroup direction={'column'}>
                        <EuiFlexItem grow={false}>
                            <MethodSelector
                                methods={methods}
                                selectedOption={stateHandler.getSelectedMethod()}
                                setSelectedOption={stateHandler.setSelectedMethod}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={true}>
                            <EuiPanel>
                                <Input code={stateHandler.getCode()} storeCode={stateHandler.setCode} setValid={setValid} />
                            </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton disabled={submissionDisabled} isLoading={stateHandler.getRunning()} onClick={handleClick}>Execute</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                    <Output response={stateHandler.getResponse()} />
                </EuiFlexItem>
            </EuiFlexGroup>
            <Error error={stateHandler.getError()} />
        </div>
    );
}
