import { CdkSpaClient } from "@cfedk/cdkspa-client";
import EditorState from "./state";

export function clickHandler(
    stateHandler: EditorState,
    client: CdkSpaClient,
) {
    return async () => {
        stateHandler.setResponse('');
        stateHandler.setError('');

        async function handleResponse(r: any) {
            stateHandler.setResponse(JSON.stringify(await r.json(), null, 2));
            stateHandler.setRunning(false);
        }

        const handleError = (error: Error) => {
            stateHandler.setError(`${error}`);
            stateHandler.setRunning(false);
        }

        client.GetUsersSubResourceBySubResourceId

        async function makePromise(methodName: string, parsedCode: any) {
            switch(methodName) {
                case 'getUsersById':
                    return await client.GetUsersById(parsedCode);
                case 'postUsers':
                    return await client.PostUsers(parsedCode);
                case 'getUsersSubResourceBySubResourceId':
                    return await client.GetUsersSubResourceBySubResourceId(parsedCode, {} as any);
                default:
                    throw `Invalid method name ${methodName}`;
            }
        }

        try {
            stateHandler.setRunning(true);

            makePromise(stateHandler.getSelectedMethod(), JSON.parse(stateHandler.getCode()))
                .then(handleResponse)
                .catch(handleError)

        } catch (e) {
            handleError(e);
        }
    };
}
