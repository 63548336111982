import * as React from 'react';
import { EuiPanel } from '@elastic/eui';

export default (props: { response?: string}) => {

    return (
        <EuiPanel style={{overflowY: 'scroll'}}>
            <pre style={{whiteSpace: 'pre-wrap'}}>{props.response || '{}'}</pre>
        </EuiPanel>
    );
}
