export type UserId = {};

export type Name = {
  first: string;
  last?: string;
};

export type User = {
  id: UserId;
  email?: string;
  name?: Name;
};

export type UserCreationRequest = {
  user: User;
};

export type PostUsersBodyParameters = UserCreationRequest;

export type GetUsersSubResourceBySubResourceIdHeaderParameters = {
  Authorization: string;
};

export interface ApiResponse<T> extends Response {
  json(): Promise<T>;
}
export type RequestFactoryType = (
  path: string,
  query: any,
  body: any,
  formData: any,
  headers: any,
  method: string,
  configuration: any
) => Promise<ApiResponse<any>>;

export class CdkSpaClient<T extends {} = {}> {
  constructor(
    protected configuration: T,
    protected requestFactory: RequestFactoryType
  ) {}
  GetUsersById(idPathParameter: number): Promise<ApiResponse<User>> {
    let path = "/users/{id}";
    path = path.replace("{id}", String(idPathParameter));
    return this.requestFactory(
      path,
      undefined,
      undefined,
      undefined,
      undefined,
      "GET",
      this.configuration
    );
  }

  PostUsers(body: PostUsersBodyParameters): Promise<ApiResponse<any>> {
    const path = "/users";
    return this.requestFactory(
      path,
      undefined,
      body,
      undefined,
      undefined,
      "POST",
      this.configuration
    );
  }

  GetUsersSubResourceBySubResourceId(
    subResourceIdPathParameter: number,
    header: GetUsersSubResourceBySubResourceIdHeaderParameters
  ): Promise<ApiResponse<User>> {
    let path = "/users/subResource/{subResourceId}";
    path = path.replace("{subResourceId}", String(subResourceIdPathParameter));
    return this.requestFactory(
      path,
      undefined,
      undefined,
      undefined,
      header,
      "GET",
      this.configuration
    );
  }
}
